<template lang="pug">
.grid.mb-4
	.title {{ isNew ? titleNew : title }}
	#teleportInDetail
	template(
		v-for="(group, key) in groups"
		:key="key"
	)
		BaseGroup(
			v-if="!(isNew && group.hideNew)"
			:title="group.title"
			:grid="group.grid"
			:count="group.count"
			:icon="isAdmin ? group.icon : null"
			@onClick="isAdmin ? group.onClick($event) : null"
			:options="group.options"
			:disabled="group.disabled"
			@onSort="group.onSort"
		)
			template(v-if="group.inputs")
				template(
					v-for="(item, key) in group.inputs"
					:key="key"
				)
					BaseCombo(
						v-if="item.search !== undefined"
						:options="item.options"
						:placeholder="item.placeholder"
						:disabled="!isAdmin"
						:required="item.required"
						:description="item.description"
						@click="item.onSearch(item.search)"
						@update:search="item.onSearch"
						v-model:value="item.value"
						v-model:search="item.search"
						@update:value="changeInitialState(false)"
					)
					BaseSelect(
						v-else-if="item.options"
						:placeholder="item.placeholder"
						:options="item.options"
						:default="item.value"
						:disabled="!isAdmin"
						:multi="item.multi"
						v-model:option="item.value"
						@update:option="changeInitialState(false)"
					)
					BaseInput(
						v-else
						:placeholder="item.placeholder"
						:type="item.type"
						:disabled="!isAdmin"
						:required="item.required"
						:minlength="item.minlength"
						:isTelephone="item.isTelephone"
						:onlyNumber="item.onlyNumber"
						:onlyText="item.onlyText"
						:pattern="item.pattern"
						@update:modelValue="changeInitialState(false)"
						v-model="item.value"
					)
			template(v-if="group.cards")
				BaseCard(
					v-for="(card, key) in group.cards"
					:key="key"
					:title="card.title"
					:subtitle="card.status"
					:icon="group.iconCard"
					:button="isAdmin ? group.button : null"
					@onClick="isAdmin ? group.buttonClick(key, card.type) : null"
				)
			template(v-if="group.collapses && group.collapses.length > 0")
				CollapseFish(
					v-for="(line, key) in group.collapses"
					:key="key"
					:head="line.head"
					:fields="line.fields"
				)
			.grid.grid--2(v-if="group.ships")
				ListShips(
					:map="group.ships"
					:isAdmin="isAdmin"
					@show="$emit('showBoat', $event)"
					@delete="$emit('deleteBoat', $event)"
				)
.flex(v-if="isAdmin" :class="`flex--jc${isNew ? 'e' : 'sb'}`")
	BaseButton(v-if="!isNew" view="danger" @click="$emit('remove')") Удалить
	BaseButton(:disabled="initialState || formIsInvalid" @click="$emit('save')") Сохранить
</template>

<script>
import { ref, watch, nextTick } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'

import { useDetail } from '../modules/composition/useDetail'

import ListShips from './lists/ListShips'

import CollapseFish from './collapses/CollapseFish'

import BaseButton from './ui/BaseButton'
import BaseGroup from './ui/BaseGroup'
import BaseCombo from './ui/BaseCombo'
import BaseInput from './ui/BaseInput'
import BaseCard from './ui/BaseCard'
import BaseSelect from './ui/BaseSelect'

export default {
	name: 'TheDetail',
	components: { BaseSelect, CollapseFish, ListShips, BaseCard, BaseInput, BaseCombo, BaseGroup, BaseButton },
	props: {
		groups: {
			type: Object,
			required: true
		},
		title: {
			type: String,
			required: true
		},
		titleNew: {
			type: String,
			required: true
		}
	},
	emits: ['showBoat', 'deleteBoat', 'remove', 'save'],
	setup (props) {
		watch(props.groups, async () => {
			await nextTick()
			formIsInvalid.value = document.querySelectorAll('.invalid, :invalid').length > 0
		})

		const formIsInvalid = ref(true)

		const isAdmin = JSON.parse(localStorage.account).isAdmin

		const { isNew, initialState, changeInitialState } = useDetail()

		onBeforeRouteLeave(() => {
			if (!initialState) {
				const answer = window.confirm(
					'Вы уверены? Некоторые поля остались не сохраннёнными.'
				)
				if (!answer) return false
			}
		})

		return {
			isAdmin,
			isNew,
			initialState,
			changeInitialState,
			formIsInvalid
		}
	}
}
</script>
