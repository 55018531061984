import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useApi } from '../api'
import { useAlert } from './useAlert'

export const useDetail = () => {
	const route = useRoute()
	const router = useRouter()

	const { path, params: { id: idDetail } } = route

	const prevPath = '/' + path.split('/')[1]

	const isNew = idDetail === 'new'

	const settingPostData = isNew
		? {}
		: {
			id: idDetail,
			registerDate: new Date().toISOString()
		}

	const { errorAlert, infoAlert } = useAlert()

	const initialState = ref(true)

	const changeInitialState = bool => {
		initialState.value = bool
	}

	const redirectPrevPath = async () => {
		await router.push(prevPath)
	}

	const notFoundDetail = async () => {
		await redirectPrevPath()

		errorAlert('Не удалось найти страницу')
	}

	const saveDetail = async ({ endpoint, body, workers, createWorkerApi, jobs, createJobApi }) => {
		const { post, data, error } = useApi(endpoint)

		await post({
			...body,
			...settingPostData
		})

		if (error.value) {
			errorAlert(`Ошибка при сохранении данных! ${error.value?.message}`)
		} else if (!isNew) {
			infoAlert('Данные успешно обновлены')

			changeInitialState(true)
		} else {
			const { id: idNew } = data.value

			if (workers) {
				await Object.keys(workers).forEach(key => {
					createWorkerApi({ id: idNew, workerId: key, postId: workers[key].postId })
				})

				changeInitialState(true)
			}

			if (jobs) {
				await Object.entries(jobs).forEach(([key, { type }]) => {
					const [orgId, postId] = key.split('---')
					createJobApi({ id: idNew, orgId, postId, type })
				})

				changeInitialState(true)
			}

			const answer = window.confirm('Данные успешно добавлены. Желаете перейти на созданную страницу для дальнейшего редактирвоания?')

			if (answer) {
				await router.push(`${prevPath}/${idNew}`)
				router.go(0)
			} else {
				await redirectPrevPath()
			}
		}
	}

	const removeDetail = async (endpoint) => {
		const { del, error } = useApi(endpoint)

		await del()

		if (error.value) {
			errorAlert(`Ошибка при удалении! ${error.value?.message}`)
		} else {
			await redirectPrevPath()
			infoAlert('Данные успешно удалены')
		}
	}

	return {
		idDetail,
		isNew,

		initialState,
		changeInitialState,

		notFoundDetail,

		saveDetail,
		removeDetail
	}
}
